@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Share+Tech&display=swap");

body {
  display: flex;
  min-height: 98vh;
  justify-content: center;
  align-items: center;
  background-color: #ecf0f1;
}

p {
  color: #bdc3c7;
  font-family: "Share Tech", sans-serif;
}

#calculator {
  padding: 8px;
  border-radius: 5px;
  background-color: #34495e;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#bottom-display {
  margin-top: 0px;
  height: 60px;
  width: 300px;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #2c3e50;
  font-size: 42px;
  color: #f1c40f;
  font-family: "Share Tech Mono", monospace;
}

#display {
  margin-bottom: 0px;
  height: 40px;
  width: 300px;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #2c3e50;
  font-size: 28px;
  color: #f1c40f;
  font-family: "Share Tech Mono", monospace;
  color: #f39c12;
}

#keyboard {
  margin: 1px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 1;

  height: 360px;
  width: 300px;
  background-color: teal;
}

button {
  background-color: #7f8c8d;
  color: white;
  font-family: "Share Tech", sans-serif;
  font-size: 26px;
}

#clear {
  background-color: #e67e22;
  grid-column-start: 1;
  grid-column-end: 3;
}

#zero {
  grid-column-start: 1;
  grid-column-end: 3;
}

#equals {
  background-color: #27ae60;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 6;
}

#divide,
#multiply,
#subtract,
#add {
  background-color: #95a5a6;
}
